import { Button } from "@material-ui/core"
import { navigate } from "gatsby"
import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import Repairphone from "../../images/repairphone.mp4"
import useGatsbyStripeData from "../hooks/useGatsbyStripeData"
import Termin from "../Termin"
import Select from "react-select"
import { brands, modelPhone, problems } from "../../data/mockData"

const getModelOptions = (obj, name) =>
  !!name ? obj[name].map(item => ({ value: item, label: item })) : []

const options = name =>
  name.map(item => ({
    value: item,
    label: item,
  }))

const defaultSelectOption = { value: "", label: "Auswählen" }

const Intro = () => {
  const [brand, setBrand] = useState(null)
  const [model, setModel] = useState(defaultSelectOption)
  const [problem, setProblem] = useState(defaultSelectOption)
  const [stepper, setStepper] = useState(0)
  const data = useGatsbyStripeData()

  return (
    <div className="boxFull hero">
      <div className="boxFull wrap-hero">
        <div className=" boxFlex intro-content">
          <div className="overlay"></div>
          <div className="background">
            <video autoPlay loop muted>
              <source src={Repairphone} type="video/mp4" />
            </video>
          </div>
          <div className="intro-content__text">
            <h1 className="h1-title">Handy und Tablet Werkstatt</h1>
            <p>
              Wir führen für Smartphones, Tablets und Notebooks aller namhaften
              Marken sämtliche Reparaturen professionell und preiswert durch und
              gewähren Ihnen eine 1-Jahres-Garantie auf unsere Leistungen.
            </p>
            <div className="boxFlex cta-btn">
              <button
                className="btn btn-raise"
                onClick={() => navigate("/termin")}
              >
                Termin{" "}
              </button>
              <button
                className="btn btn-bg btn-raise"
                onClick={() => navigate("/services")}
              >
                Services
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Intro
