import emailjs, { init } from "emailjs-com"
import { navigate } from "gatsby"

const userEmailjs = process.env.GATSBY_EMAILJS

init(userEmailjs)

export const sendEmailTermin = ({
  name,
  number,
  problem,
  email,
  terminDate,
}) => {
  const templateParams = {
    message_html: `<div><ol style="font-size: 18px">
      <li>Name: ${name}  </li>
      <li>Handy Nummer:  ${number}  </li>

      <li>Time: ${terminDate}  </li>
      <li>Problem: <span style='color : red'>${problem}</span>  </li>
      </ol></div>`,
    from_name: `PhoneABC GmbH`,
    to_bcc: email,
  }

  emailjs
    .send("service_l634urs", "template_rt2n1wt", templateParams, userEmailjs)
    .then(
      result => {
        alert(`Thanks for Termin. Check Your ${email} to review your termin`)
        navigate("/")
      },
      error => {
        console.log(error.text)
      }
    )
}

export const sendMailContact = () => {}
