import React, { useState } from "react"
import { navigate } from "gatsby"
import { sendEmailTermin } from "../../sendmail"
import emailjs, { init } from "emailjs-com"

init("user_pjN71AkA6f8IUCEG6ohxc")

const Termin = () => {
  const [terminDate, setTerminDate] = useState(new Date())
  const [name, setName] = useState("")
  const [number, setNumber] = useState("")
  const [email, setEmail] = useState("")
  const [problem, setProblem] = useState("")

  const sendEmail = () => {
    sendEmailTermin({
      name,
      number,
      problem,
      email,
      terminDate,
    })
  }
  return (
    <form
      className="termin form-share"
      onSubmit={e => {
        e.preventDefault()
        sendEmail()
      }}
    >
      <h1>Termin Buchen</h1>
      <label className="form-label" htmlFor="form-name">
        Ihr Name
      </label>
      <input
        id="form-name"
        className="form-name"
        type="text"
        // placeholder="Ihr Name"
        value={name}
        onChange={e => {
          setName(e.target.value)
        }}
        required
      />
      <label className="form-label" htmlFor="form-number">
        Handy Numer
      </label>
      <input
        id="form-number"
        className="form-number"
        type="number"
        // placeholder="Handy Numer"
        value={number}
        onChange={e => {
          setNumber(e.target.value)
        }}
        required
      />
      <label className="form-label" htmlFor="form-email">
        Email Addresse
      </label>
      <input
        id="form-email"
        className="form-email"
        type="email"
        // placeholder="Email Address"
        value={email}
        onChange={e => {
          setEmail(e.target.value)
        }}
        required
      />
      <label className="form-label" htmlFor="meeting-time">
        {" "}
        Time{" "}
      </label>
      <input
        className="form-time"
        type="datetime-local"
        id="meeting-time"
        name="meeting-time"
        value={terminDate}
        onChange={e => {
          setTerminDate(e.target.value)
        }}
        min="2020-06-07T00:00"
        max="2022-10-14T00:00"
      />
      <label className="form-label" htmlFor="termin-problem">
        Problembeschreibung
      </label>
      <textarea
        id="termin-problem"
        // placeholder="Problembeschreibung"
        onChange={e => {
          setProblem(e.target.value)
        }}
      />
      <input className="form-submit" type="submit" value="Termin buchen" />
    </form>
  )
}

export default Termin
