import React, { useEffect } from "react"
import { graphql } from "gatsby"

import SEO from "../components/seo"
import Intro from "../components/Intro"
import Services from "../components/Services"
import Wedo from "../components/Wedo"
import Contact from "../components/Contact"
import Layout from "../components/Layout/layout"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data?.site?.siteMetadata?.title || `Title`
  const handleScroll = e => {
    const siteHeader = document.querySelector(".site-header")
    const linkItem = document.querySelectorAll(".header-link__item")
    const element = e.target.firstElementChild
    if (element.scrollTop > 0) {
      // siteHeader.style.boxShadow = "0 0 1px 1px #cecece"
      siteHeader.style.background = "black"
      ;[...linkItem].forEach(item => (item.style.color = "white"))
    } else {
      siteHeader.style.background = "unset"
      // ;[...linkItem].forEach(item => (item.style.color = "black"))
      // siteHeader.style.boxShadow = "unset"
    }
  }

  useEffect(() => {
    const siteHeader = document.querySelector(".site-header")
    siteHeader.style.background = "transparent"

    window.addEventListener("scroll", handleScroll)
    return () => {
      const siteHeader = document.querySelector(".site-header")

      siteHeader.style.background = "black"
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    <Layout>
      {/* <Layout location={location} title={siteTitle}> */}
      <SEO title="Repairphone24.de" />
      <Intro location={location} />
      <Services />
      <Wedo />
      <Contact />
      {/* </Layout> */}
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`
