import * as React from "react"

function SvgWedoit(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 496" {...props}>
      <path d="M0 0v400h133.992C120.336 412 112 429.424 112 448v48h48v-48c0-8.824 7.176-16 16-16s16 7.176 16 16v48h48v-48c0-18.576-8.336-36-21.992-48H288v56c0 22.056 17.944 40 40 40s40-17.944 40-40v-56h128V0H0zm224 448v32h-16v-32c0-17.648-14.352-32-32-32s-32 14.352-32 32v32h-16v-32c0-18.384 10.768-35.384 27.44-43.312l4.56-2.176V221.488l-4.56-2.168C138.768 211.384 128 194.384 128 176v-32h16v32c0 17.648 14.352 32 32 32s32-14.352 32-32v-32h16v32c0 18.384-10.768 35.384-27.44 43.312l-4.56 2.176V402.52l4.56 2.168C213.232 412.616 224 429.616 224 448zm64-64h-80v-16h80v16zm64 72c0 13.232-10.768 24-24 24s-24-10.768-24-24V336h16v24h16v-24h16v120zm-39.32-282.632L318.56 144h18.88l5.872 29.368-7.312 7.32V320h-16V180.688l-7.32-7.32zM352 320V187.312l8.68-8.68L350.56 128h-45.12l-10.128 50.632 8.688 8.68V320h-32v16h16v16h-80V231.36C227.592 220 240 198.8 240 176v-48h-48v48c0 8.824-7.176 16-16 16s-16-7.176-16-16v-48h-48v48c0 22.8 12.408 44 32 55.36V352H48V112h400v240h-80v-16h16v-16h-32zm128 64H368v-16h96V96H32v272h112v16H16V80h464v304zm0-320H16V16h464v48z" />
      <path d="M32 32h16v16H32zM64 32h16v16H64zM96 32h16v16H96zM320 376h16v16h-16zM368 248h16v16h-16zM400 248h16v16h-16zM80 248h16v16H80zM112 248h16v16h-16z" />
    </svg>
  )
}

export default SvgWedoit
